/** Here to Work: Richard digging **/

import React from "react";
import { preloadImages, showText, frameAnimation, scaleFullscreenBottomAligned, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Linear, Quad, /*steps*/ } from "gsap";

let globalState, data, preloadData, steamTL1, steamTL2, rockTL;
let diggingRockObj = {};
let rockSteps = [
    {x:"808px", y:"642px"}, 
    {x:"825px", y:"642px"}, 
    {x:"865px", y:"602px"}, 
    {x:"975px", y:"515px"}, 
    {x:"971px", y:"390px"}, 
    {x:"899px", y:"295px"}
];

class HereToWorkPage4 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[4];
        preloadData = globalState.assets[5];

        //refs
        this.fullscreenBg = null;
        this.dig1 = this.dig2 = this.dig3 = this.dig4 = this.dig5 = this.dig6 = this.dig7 = this.dig8 = this.dig9 = this.dig10 = this.dig11 = this.dig12 = this.dig13 = this.dig14 = this.dig15 = this.digStand = this.initSteamSnd = null;
    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        //add base track
        globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.soundfile1); //VO
        
        globalState.baseAudioRef.updateVolume(1, 70); //mine

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        
        this.dig1 = setTimeout(() => {
            this.dig(0.7, data.shovelSound1);
        }, 600);

        this.dig2 = setTimeout(() => {
            this.dig(0.7, data.shovelSound2);
        }, 2800);
        
        this.dig3 = setTimeout(() => {
            //bounce rock
            this.dig(0.5, data.shovelSound3, this.throwRockBounce );
        }, 5200);
        
        this.dig4 = setTimeout(() => {
            //speed up
            this.dig(0.3, data.shovelSound4);
        }, 6500);
        
        this.dig5 = setTimeout(() => {
            this.dig(0.2);
        }, 7100);
        
        this.dig6 = setTimeout(() => {
            this.dig(0.1);
            this.initTrainPile(0.1);
        }, 7700);
        
        this.dig7 = setTimeout(() => {
            this.dig(0.1);
            gsap.to(this.refs.diggerOverlayRef, 6, {opacity: 1, ease:Quad.easeIn, onComplete:this.pauseSteam});
            this.moveTrainPile(0.1);
        }, 7200);
        
        this.dig8 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 7700);
        
        this.dig9 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 8200);
        
        this.dig10 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 8700);
        
        this.dig11 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 9200);
        
        this.dig12 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 9700);
        
        this.dig13 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 10200);
        
        this.dig14 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 10700);
        
        this.dig15 = setTimeout(() => {
            this.dig(0.1);
            this.moveTrainPile(0.1);
        }, 11200);
        
        this.digStand = setTimeout(() => {
            this.refs.diggerStandRef.style.display = "block";
            this.refs.diggerSpriteRef.style.display = "none";
        }, 13000);


        this.initSteamSnd = setTimeout(() => {
            let snd = new Audio();
            snd.src = globalState.cdnUrl + data.steamSound;
            snd.volume = 0.7;
            if(!globalState.baseAudioRef.isMuted()){
                snd.play();
            }
            this.initSteamCloud();
            gsap.to(this.refs.whiteBgRef, 3.5, {opacity: 0.9});
            
        }, 15000);

        //show text

        gsap.set(this.refs.text1Ref, { x:"300", y:"50", rotate:"3deg", autoAlpha: 0});
        gsap.to(this.refs.text1Ref, 0, { x:0, y:0, rotate:"0deg", autoAlpha: 1.0, delay: 20});
        
        
        //Animate Steam
        steamTL1 = gsap.timeline({ repeat: -1 });
        steamTL2 = gsap.timeline({ repeat: -1});
        gsap.set(this.refs.steam1Ref, {alpha: 0, scale: 1});
        gsap.set(this.refs.steam2Ref, {alpha: 0.5, scale: 1.05});
        
        steamTL1.to(this.refs.steam1Ref, 3.33, { alpha: 1, scale: 1.09, ease: Linear.easeNone });
        steamTL1.to(this.refs.steam1Ref, 3.33, { alpha: 0, scale: 1.2, ease: Linear.easeNone });
        
        steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 1, scale: 1.09, ease: Linear.easeNone });
        steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 0.5, scale: 1.15, ease: Linear.easeNone });
        steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 0, scale: 1.2, ease: Linear.easeNone });
        steamTL2.to(this.refs.steam2Ref, 0, { alpha: 0, scale: 1 });
        steamTL2.to(this.refs.steam2Ref, 1.66, { alpha: 0.5, scale: 1.05, ease: Linear.easeNone });
        

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.bgVideoPoster,
            globalState.cdnUrl + preloadData.text1,
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        
        gsap.killTweensOf(this.refs.steamWrapperRef);
        gsap.killTweensOf(this.refs.diggingPileRef);
        gsap.killTweensOf(steamTL1);
        gsap.killTweensOf(steamTL2);
        gsap.killTweensOf(rockTL);

        //clear timeouts
        clearTimeout(this.dig1);
        clearTimeout(this.dig2);
        clearTimeout(this.dig3);
        clearTimeout(this.dig4);
        clearTimeout(this.dig5);
        clearTimeout(this.dig6);
        clearTimeout(this.dig7);
        clearTimeout(this.dig8);
        clearTimeout(this.dig9);
        clearTimeout(this.dig10);
        clearTimeout(this.dig11);
        clearTimeout(this.dig12);
        clearTimeout(this.dig13);
        clearTimeout(this.dig14);
        clearTimeout(this.dig15);
        clearTimeout(this.digStand);
        clearTimeout(this.initSteamSnd);

        //remove base track
        globalState.baseAudioRef.updateTrack(3, false); //clear richard vo

        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);

        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");
        }
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 725
        //scaleFullscreenBottomAligned(this.refs.fullscreenBgDiggerRef, window.innerWidth, window.innerHeight, 1287, 725);
        scaleFullscreenBottomAligned(this.refs.fullscreenBgDiggerRef, window.innerWidth, window.innerHeight, 1287, 725, true);
        
    }

    isTransitioning = () => {

        //pause animations
    }

    dig = (speed, sndFile, throwRock = this.throwRock ) => {

        this.refs.digger1Ref.style.display = "none";
        this.refs.diggerSpriteRef.style.display = "block";

        console.log("dig: " + speed + " snd: " + sndFile);
        //digger sprite animation
        frameAnimation(this.refs.diggerSpriteRef, 416, 400, 6, 1, 6, speed, 0);
        
        //rock following digger sprite
        //init rockTL
        rockTL = gsap.timeline();
        diggingRockObj.count = 0;
        rockTL.add(gsap.to(diggingRockObj, speed, { count: 5,
            onUpdate: this.updateRockPos, ease: 'steps('+ 5 +')', onComplete:throwRock, onCompleteParams:[speed]
        }));

        //shift pile
        gsap.to(this.refs.diggingPileRef, 0.5, {delay: speed/10, x:"+=5px", ease:Quad.easeOut});

        //play sound
        if(sndFile){
            setTimeout(() => {
                let shovelSound = new Audio();
                shovelSound.src = globalState.cdnUrl + sndFile;
                if(!globalState.baseAudioRef.isMuted()){
                    shovelSound.play();
                }
            }, 300);
        }
        //bounce train
        setTimeout(() => {
            this.bounceTrain();
        }, (speed*2) * 1000);
    }

    updateRockPos = () => {
        console.log("update rock position " + diggingRockObj.count);
        let count = Math.floor(diggingRockObj.count);
        gsap.set(this.refs.diggingRockWrapperRef, {x:rockSteps[count].x, y:rockSteps[count].y});
    }

    throwRock = (speed) => {
        gsap.to(this.refs.diggingRockWrapperRef, speed/2, {x:"473px", y:"123px"});
        gsap.to(this.refs.diggingRockWrapperRef, speed/2, {x:"199px", y:"345px", delay:speed/2});

        //reset sprite and rock
        gsap.set(this.refs.diggerSpriteRef, {backgroundPosition: '0 0', delay: speed});
        diggingRockObj.count = 0;
        gsap.set(this.refs.diggingRockWrapperRef, {x:rockSteps[0].x, y:rockSteps[0].y, delay: speed});
    }

    throwRockBounce = () => {
        gsap.to(this.refs.diggingRockWrapperRef, 0.4, {x:"473px", y:"123px"});
        gsap.to(this.refs.diggingRockWrapperRef, 0.2, {x:"419px", y:"235px", delay:0.4});
        gsap.to(this.refs.diggingRockWrapperRef, 0.2, {x:"309px", y:"215px", delay:0.6});
        gsap.to(this.refs.diggingRockWrapperRef, 0.2, {x:"309px", y:"315px", delay:0.8});

        //reset sprite and rock
        gsap.set(this.refs.diggerSpriteRef, {backgroundPosition: '0 0', delay: 1});
        diggingRockObj.count = 0;
        gsap.set(this.refs.diggingRockWrapperRef, {x:rockSteps[0].x, y:rockSteps[0].y, delay: 1});
    }

    bounceTrain = () => {
        console.log("bounceTrain");
        gsap.to(this.refs.diggingTrainRef, 0, {y:"4px"});
        gsap.to(this.refs.diggingTrainRef, 0, {y:"0", delay: 0.2});
    }

    pauseSteam = () => {
        steamTL1.pause();
        steamTL2.pause();
        gsap.to(this.refs.steam1Ref, 1, {alpha: 0});
        gsap.to(this.refs.steam2Ref, 1, {alpha: 0});
    }

    initSteamCloud = () => {
        gsap.set(this.refs.steam1Ref, {scale:0.6, x:"-200px"});
        gsap.to(this.refs.steam1Ref, 4, {alpha: 1, scale:1.5, rotate:"-15deg", ease:Quad.easeInOut});
        gsap.to(this.refs.steam1Ref, 0.5, {alpha: 0, scale:1.6});
    }

    initTrainPile = (speed) => {
        let tl = gsap.timeline();
        gsap.set(this.refs.diggingTrainpileRef, {x:"-2px", y:"-40px", opacity:1});
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-2px", y:"-=20"}));
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-16px", y:"-=15"}));
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.3, {x:"-16px", y:"-=40"}));
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-4px", y:"+=8"}), 0.1);
    }

    moveTrainPile = (speed) => {
        let tl = gsap.timeline();
        
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-6px", y:"-=10"}));
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-14px", y:"-=0"}));
        tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-6px", y:"-=10"}));
        //tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-14px", y:"-=10"}), 0.1);
        //tl.add(gsap.to(this.refs.diggingTrainpileRef, 0.1, {x:"-6px", y:"-=0"}));
    }

    render() {

        return (

            <>

                <link rel="prefetch" href="/here-to-work/5" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/here-to-work/5" prevLocation="/here-to-work/3"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Here To Work" />

                    <div className="fullscreen-bg digging-wrapper" ref="fullscreenBgDiggerRef">
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background digger-bg" />
                        <img src={globalState.cdnUrl + data.diggingOverlay} alt="background" className="background digger-overlay" ref="diggerOverlayRef" />
                        <div ref="steamWrapperRef" className="steamWrapper">
                            <img src={globalState.cdnUrl + data.diggingSteam} alt="Digging Steam" className="steam" ref="steam1Ref" />
                            <img src={globalState.cdnUrl + data.diggingSteam} alt="Digging Steam" className="steam" ref="steam2Ref" style={{opacity: "0.5", marginLeft: "59px", marginTop: "37px"}} />
                        </div>
                        <div className="diggingTrainpileWrapper" ref="diggingTrainpileWrapperRef">
                            <img className="diggingTrainpile" ref="diggingTrainpileRef" src={globalState.cdnUrl + data.diggingTrainpile} alt="Digging Train pile" />
                        </div>
                        <img className="diggingLine" ref="diggingLineRef" src={globalState.cdnUrl + data.diggingLine} alt="Digging Line" />
                        <img className="diggingPile" ref="diggingPileRef" src={globalState.cdnUrl + data.diggingPile} alt="Digging Pile" />
                        <div className="diggerSprite" ref="diggerSpriteRef" style={{ backgroundImage: `url(${globalState.cdnUrl + data.diggerSprite})`}}></div>
                        <img className="digger1" ref="digger1Ref" src={globalState.cdnUrl + data.digger1} alt=""/>
                        <img className="diggerStand" ref="diggerStandRef" src={globalState.cdnUrl + data.diggerStand} alt=""/>
                        <div className="diggingRockWrapper" ref="diggingRockWrapperRef">
                            <img className="diggingRock" src={globalState.cdnUrl + data.diggingRock} alt="Digging Rock" />
                        </div>
                        <img className="diggingTrain" ref="diggingTrainRef" src={globalState.cdnUrl + data.diggingTrain} alt="Digging Train" />
                        <div className="white-bg" ref="whiteBgRef" />
                    </div>

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "6vw", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="And who are you to judge?" />
                        </div>
                    </div>

                </div>
                )}
            </>
        );
    }


};

export default HereToWorkPage4
